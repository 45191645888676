import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableConfig, TableService } from 'src/app/common/services/TableService';
import { ASourceFileUm4Meta } from 'src/app/common/types/helpers/ASourceFileUm4Meta';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ComponentService } from 'src/app/services/ComponentService';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { DeleteATemplateCmdlet } from '../../cmdlets/DeleteATemplateCmdlet';
import { DownloadATemplateCmdlet } from '../../cmdlets/DownloadATemplateCmdlet';
import { UpdateATemplateCmdlet4Meta } from '../../cmdlets/UpdateATemplateCmdlet4Meta';
import { DownloadSourceFileReq } from '../../types/helpers/DownloadSourceFileReq';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { GetBaseTemplateMetaCmdlet } from '../../cmdlets/GetBaseTemplateMetaCmdlet';
import { UploadReplaceTemplateComponent } from 'src/app/common/components/upload-replace-template/upload-replace-template.component';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { SelectOption } from 'src/app/common/types/helpers/SelectOption';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent, DialogButtonType } from 'src/app/common/components/confirmation-dialog/confirmation-dialog.component';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-base-template-details',
  templateUrl: './base-template-details.component.html',
  styleUrls: ['./base-template-details.component.scss']
})
export class BaseTemplateDetailsComponent extends OverviewComponentBase<TemplateOvm> implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private titleService: Title,
    componentService: ComponentService,
    private downloadATemplateCmdlet: DownloadATemplateCmdlet,    
    private getBaseTemplateMetaCmdlet: GetBaseTemplateMetaCmdlet,    
    private deleteATemplateCmdlet: DeleteATemplateCmdlet,
    private updateTemplateMetaCmdlet: UpdateATemplateCmdlet4Meta,
    public componentContext: ComponentContext,
    private dialog: MatDialog,    
    private _sanitizer: DomSanitizer,
    private logger: AppInsightsLoggerService,
    tableService: TableService<TemplateOvm>,
    private alertService: AlertService) {
    super(tableService, componentService)
  }

  selectedBaseTemplateId: number;
  baseTemplate: BaseTemplateOdm;
  imagePath: any;
  actionBusy: boolean = false;
  exportItems: SelectOption[] = [];
  breadcrumbs: Breadcrumb[];
  editRaetName: string;

  private text_error_deleting_template: string;
  private text_error_download_document: string
  private text_error_saving_template: string;
  private text_template_saved: string;
  private text_delete_confirmation: string;
  private text_base_templates: string;
  private text_error_retrieving_data: string;
  private text_error_name_required: string;

  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }
 
  async ngOnInit() {

    this.translate.get([
      'labels.export_to_docx', 
      'labels.export_to_doc', 
      'labels.export_to_bin',
      'errors.deleting_template',
      'errors.saving_template',
      'messages.template_saved',
      'messages.delete_confirmation',
      'headers.base_templates',
      'errors.retrieving_data',
      'errors.name_required'
    ]).subscribe(translations => {
      this.exportItems = [
        { id: '.docx', text: translations['labels.export_to_docx'] },
        { id: '.doc', text: translations['labels.export_to_doc'] },
        { id: '.bin', text: translations['labels.export_to_bin'] }
      ];
      this.text_error_deleting_template = translations['errors.deleting_template'];
      this.text_error_download_document = translations['errors.download_document'];
      this.text_error_saving_template = translations['errors.saving_template'];
      this.text_template_saved = translations['messages.template_saved'];
      this.text_delete_confirmation = translations['messages.delete_confirmation'];
      this.text_base_templates = translations['headers.base_templates'];
      this.text_error_retrieving_data = translations['errors.retrieving_data'];
      this.text_error_name_required = translations['errors.name_required'];
    });

    this.route.paramMap.subscribe(async params => {
            
      this.selectedBaseTemplateId = Number(params.get('baseTemplateId'));
      
      await this.refreshData();

      this.translate.get('pageTitles.base_template_details', { templateName: this.baseTemplate.raetName }).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });

      this.breadcrumbs = [
        { label: this.text_base_templates, url: ['/base-templates', 'details'] },
        { label: this.baseTemplate.raetName }
      ];
      
    });

    super.ngOnInit();
  }

  

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  onEditemplateClicked($event) {
    // Force reload to make sure the editor is loaded correctly
    const currentParams = new URLSearchParams(window.location.search);
    const activeParam = currentParams.get('active');
    document.location.href = '/base-templates/' + this.selectedBaseTemplateId + '/editor' + (activeParam ? '?active=' + activeParam : '');    
  }

  async onDeleteTemplateClicked($event) {    

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        confirmationText: this.text_delete_confirmation,
        buttons: [DialogButtonType.Yes, DialogButtonType.No],
        defaultButton: DialogButtonType.No
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === DialogButtonType.Yes) {       
        try {
          await this.deleteATemplateCmdlet.execute(this.selectedBaseTemplateId);
          this.router.navigate(['/base-templates', 'details'], { queryParamsHandling: 'merge' });
        } catch (error) {
          console.error('Delete base template failed', error);
          this.logger.error(error);
          this.showAlert(AlertTypeEnum.warning, this.text_error_deleting_template);
        }
      } 
    });
  }

  async onExportTemplateClicked(selectedOption: SelectOption) {
    
    try {
      this.actionBusy = true;

      let req = new DownloadSourceFileReq();
      req.templateId = this.selectedBaseTemplateId;

      switch (selectedOption.id) {
        case '.docx': {
          req.documentType = 0;
          break;
        }
        case '.doc': {
          req.documentType = 1;
          break;
        }
        case '.bin': {
          req.documentType = 2;
          break;
        }
      }

      const fileBlob = await this.downloadATemplateCmdlet.execute(req);

      // Now provide the file for download
      const fileName = this.baseTemplate.raetName + selectedOption.id;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      const url = window.URL.createObjectURL(fileBlob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      
      this.actionBusy = false;
    } catch (error) {
      console.error('Export base template failed', error);
      this.logger.error(error);
      this.actionBusy = false;
      this.showAlert(AlertTypeEnum.warning, this.text_error_download_document);
    }
  }

  async onSaveTemplateClicked($event) {

    // Show error if no name was entered
    if (!this.editRaetName) {
      this.showAlert(AlertTypeEnum.warning, this.text_error_name_required);
      return;
    }

    try {
      this.actionBusy = true;

      let model = this.getModel();
      model.name = this.editRaetName;

      await this.updateTemplateMetaCmdlet.execute(model);

      // Make sure the name is not longer than the max 50 characters
      this.baseTemplate.raetName = this.editRaetName.length > 50 ? this.editRaetName.substring(0, 50) : this.editRaetName;

      this.actionBusy = false;
      this.showAlert(AlertTypeEnum.success, this.text_template_saved);
    } catch (error) {
      console.error('Save base template failed', error);
      this.logger.error(error);
      this.actionBusy = false;
      this.showAlert(AlertTypeEnum.warning, this.text_error_saving_template);
    }
  }

  private getModel() {
    let model = new ASourceFileUm4Meta();
    //model.name = this.f.name.value;
    model.id = this.selectedBaseTemplateId;
    model.name = this.baseTemplate.raetName;
    // model.description = this.f.fileDescription.value;
    // model.fileType = this.selectedFileType;
    return model;
  }

  onThumbnailError() {
    this.imagePath = null;
  }

  onDeleteRowClicked(row: TemplateOvm) {
    //throw new Error('Method not implemented.');
  }
  onAddNewRowClicked(arg: any) {
    this.componentContext.isDiaglogShown = true;
    this.addNewTemplateViaUpload();
  }

  addNewTemplateViaUpload() {
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.panelClass = 'tm-dialog';
    const dialogRef1 = this.dialog.open<UploadReplaceTemplateComponent, any, boolean>(UploadReplaceTemplateComponent, config);
    const dialogInstance2 = dialogRef1.componentInstance;
    dialogInstance2.templateIdToReplace = this.selectedBaseTemplateId;
    dialogRef1.afterClosed().subscribe(async (result) => {
      this.componentContext.isDiaglogShown = false;
      await this.refreshData();
      await this.reloadView();
    });
  }

  onEditRowClicked(row: TemplateOvm) {
  }
  onViewRowClicked(row: TemplateOvm) {
  }
  loadRows(): Promise<TemplateOvm[]> {
    return Promise.resolve([]);
  }
  get tableConfig(): TableConfig {
    return new TableConfig()
  }

  onRedoTemplateClicked($event) {
    this.router.navigate(['/base-templates', this.selectedBaseTemplateId, 'empty-editor'], { queryParamsHandling: 'merge' }).then(() => { window.location.reload(); });
  }

  private async refreshData() {
    this.isLoaded = false;

    try {
      this.baseTemplate = await this.getBaseTemplateMetaCmdlet.execute(this.selectedBaseTemplateId);      
      this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.baseTemplate.thumbnail);

      this.editRaetName = this.baseTemplate.raetName;
    } catch (error) {
      console.error('Get base template meta failed', error);
      this.logger.error('Error getting base template', error);
      this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);
    }
    finally {
      this.isLoaded = true;
    }
  }
}
