import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { GetTemplateBlobCmdlet2 } from '../../../templates/cmdlets/GetTemplateBlobCmdlet2';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { DataDefinitionDm } from '../../../templates/types/DataDefinitionDm';
import { UpdateATemplateCmdlet4Content } from 'src/app/base-templates/cmdlets/UpdateATemplateCmdlet4Content';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { GetBaseTemplateMetaCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplateMetaCmdlet';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from 'src/app/common/api-clients/session.service';
import { CanComponentDeactivate } from 'src/app/common/guards/unsaved-changes.guard';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';
import { EditorStateService } from 'src/app/services/EditorStateService';

declare const TXTextControl: any;
declare const loadDocument: any;

@Component({
  selector: 'app-base-template-editor',
  templateUrl: './base-template-editor.component.html',
  styleUrl: './base-template-editor.component.scss'
})
export class BaseTemplateEditorComponent implements OnInit, AfterViewInit, CanComponentDeactivate {
  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private titleService: Title,
    private sessionService: SessionService,
    private editorStateService: EditorStateService,
    private getTemplateBlobCmdlet2: GetTemplateBlobCmdlet2,    
    private getBaseTemplateMetaCmdlet: GetBaseTemplateMetaCmdlet,  
    private updateATemplateCmdlet4Content: UpdateATemplateCmdlet4Content,
    public componentContext: ComponentContext,    
    private logger: AppInsightsLoggerService,
    private router: Router,
    private alertService: AlertService) {
      this.ribbonTabsLoadedHandler = this.onRibbonTabsLoaded.bind(this);
     }

  templateContentDto: TemplateEditorFileModel;
  templateBlob: Blob;
  isLoaded: boolean = false;
  showCustomDialog: boolean = false;
  mergeDataSpecification: DataDefinitionDm;
  content: any;
  templateName: string;
  baseTemplateId: number;  
  myCulture: string;
  loadError: boolean = false;
  contentChanged: boolean = false;
  saveInProgress: boolean = false;
  breadcrumbs: Breadcrumb[];
  ribbonsLoaded: boolean = false;
  
  private text_template_saved: string;
  private text_template_not_saved: string;  
  private text_error_retrieving_data: string;
  private text_leave_confirmation: string;
  private text_base_templates: string;
  private text_edit: string;
  private text_template_restored: string;

  private document: Blob;
  private documentLoadedHandler = this.onDocumentLoaded.bind(this);
  private changedHandler = this.onChanged.bind(this);
  private ribbonTabsLoadedHandler: () => void;  

  @ViewChild('documentEditorContainer', { static: false }) documentEditorContainer!: ElementRef;

  @HostListener('document:txDocumentEditorLoaded', ['$event'])
  onTxDocumentEditorLoaded() {

    // Controleer of de event listeners al zijn toegevoegd
    if (this.editorStateService.isTxTextControlInitialized) {
      return;
    }

    // Voeg event listeners toe aan TXTextControl
    TXTextControl.addEventListener("ribbonTabsLoaded", this.ribbonTabsLoadedHandler);
    TXTextControl.addEventListener("documentLoaded", this.documentLoadedHandler);

    this.editorStateService.isTxTextControlInitialized = true;

    // Laad het document als het beschikbaar is
    if (this.document) {
      this.loadDocument(this.document);
    }
  }

  private onRibbonTabsLoaded() {
    this.setStyling();
    this.ribbonsLoaded = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeDocumentEditor();
  }

  async ngOnInit(): Promise<void> {

    this.myCulture = this.sessionService.session.culture;
    this.isLoaded = false;

    this.translate.get([     
      'messages.template_saved',
      'errors.saving_template',      
      'errors.retrieving_data',
      'messages.leave_confirmation',
      'headers.base_templates',
      'headers.edit',
      'messages.template_restored'
    ]).subscribe(translations => {           
      this.text_template_saved = translations['messages.template_saved'];
      this.text_template_not_saved = translations['errors.saving_template'];      
      this.text_error_retrieving_data = translations['errors.retrieving_data'];
      this.text_leave_confirmation = translations['messages.leave_confirmation'];
      this.text_base_templates = translations['headers.base_templates'];
      this.text_edit = translations['headers.edit'];
      this.text_template_restored = translations['messages.template_restored'];
    });

    this.route.paramMap.subscribe(async params => {
      try {
        this.baseTemplateId = Number(params.get('baseTemplateId'));
        this.templateBlob = await this.getTemplateBlobCmdlet2.execute(this.baseTemplateId);
        const baseTemplate = await this.getBaseTemplateMetaCmdlet.execute(this.baseTemplateId);      
        this.templateName = baseTemplate.raetName;

        this.translate.get('pageTitles.base_template_edit', { templateName: this.templateName }).subscribe((title: string) => {
          this.titleService.setTitle(title);
        });

        this.loadDocument(this.templateBlob);

        this.breadcrumbs = [
          { label: this.text_base_templates, url: ['/base-templates', 'details'] },
          { label: this.templateName, url: ['/base-templates', this.baseTemplateId, 'details'] },       
          { label: this.text_edit }
        ];

        this.isLoaded = true;
      } catch (error) {
        console.error('Loading base template failed', error);
        this.logger.error(error);
        this.isLoaded = true;
        this.loadError = true;
        this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);
      }

      // Laad het document als TXTextControl al beschikbaar is
      if (typeof TXTextControl !== 'undefined') {
        this.loadDocument(this.document);
      }
    });    
  }

  canDeactivate(): boolean {
    return !this.contentChanged;
  }

  get deactivationMessage(): string {
    return this.text_leave_confirmation;
  }

  private onDocumentLoaded() {
    // Bind to changed event after document was loaded    
    TXTextControl.addEventListener("changed", this.changedHandler);
    this.resizeDocumentEditor();
  }
  
  private onChanged() {
    this.contentChanged = true;    
  }

  ngAfterViewInit() {        
    this.resizeDocumentEditor();    
  }

  blobToBase64(blob: Blob, callback: (base64: string) => void): void {
    if (!(blob instanceof Blob)) {
      console.error('blobToBase64: Provided argument is not a Blob', blob);
      return;
    }
  
    const reader = new FileReader();
    reader.onload = () => {
      callback((reader.result as string).split(',')[1]);
    };
    reader.onerror = (error) => {
      console.error('blobToBase64: FileReader error', error);
    };
    reader.readAsDataURL(blob);
  }
  

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  private loadDocument(document: Blob) {
    this.blobToBase64(document, (encoded) => {
      TXTextControl.loadDocument(TXTextControl.StreamType.InternalUnicodeFormat, encoded);
      this.contentChanged = false;
    });
  }

  onCompleteTemplateClicked($event) {
    // Only save if needed
    if (this.contentChanged) {
      this.onSaveTemplateClicked($event).then(() => {        
        this.router.navigate(['/base-templates', this.baseTemplateId, 'details'], { queryParamsHandling: 'merge' });
        this.showAlert(AlertTypeEnum.success, this.text_template_saved);
      });
    } else {
      // Nothing to save, just go back
      this.router.navigate(['/base-templates', this.baseTemplateId, 'details'], { queryParamsHandling: 'merge' });
    }
  }

  async onSaveTemplateClicked($event) {
    this.saveInProgress = true;
    const self = this;
    let templateId = this.baseTemplateId;    
    
    return new Promise((resolve, reject) => {
      TXTextControl.saveDocument(TXTextControl.StreamType.InternalUnicodeFormat, async function (e) {
        if (e) {
          let templateEditorFileModel = new TemplateEditorFileModel();
          templateEditorFileModel.id = templateId;
          templateEditorFileModel.content = e.data
          templateEditorFileModel.name = this.templateName
          
          try {
            await self.saveDocument(templateEditorFileModel);
            self.saveInProgress = false;
            self.contentChanged = false;
          }
          catch (error) {
            self.saveInProgress = false;
            self.showAlert(AlertTypeEnum.warning, self.text_template_not_saved);
            reject('Error saving document');
          }

          resolve(e.data);
        } else {
          self.saveInProgress = false;
          self.showAlert(AlertTypeEnum.warning, this.text_template_not_saved);
          reject('Error saving document');
        }
      });
    });
  }

  async saveDocument(templateEditorFileModel) {
    try {
      await this.updateATemplateCmdlet4Content.execute(templateEditorFileModel);
      this.showAlert(AlertTypeEnum.success, this.text_template_saved);
    } catch (error) {  
      this.logger.error('Save base template failed', error);    
      throw error;
    }
  }

  onCancelTemplateClicked($event) {
    this.router.navigate(['/base-templates', this.baseTemplateId, 'details'], { queryParamsHandling: 'merge' });
  }

  async onClearTemplateClicked($event) {
    this.loadDocument(this.document);
    this.showAlert(AlertTypeEnum.success, this.text_template_restored);
  }
  
  setStyling() {
    let tabProofing = document.getElementById('tabProofing') as HTMLElement;
    tabProofing?.style.setProperty('display', 'none')

    let tabPermissions = document.getElementById('tabPermissions') as HTMLElement;
    tabPermissions?.style.setProperty('display', 'none')

    let tabReferences = document.getElementById('tabReferences') as HTMLElement;
    tabReferences?.style.setProperty('display', 'none')

    let tabFormFields = document.getElementById('tabFormFields') as HTMLElement;
    tabFormFields?.style.setProperty('display', 'none')

    let txTglBtnSideBars = document.getElementById('txTglBtnSideBars') as HTMLElement;
    txTglBtnSideBars?.style.setProperty('visibility', 'hidden')

    let ribbonTabInsert_drpDnBtnInsertChart = document.getElementById('ribbonTabInsert_drpDnBtnInsertChart') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertChart?.style.setProperty('display', 'none')

    let ribbonTabInsert_drpDnBtnInsertBarcode = document.getElementById('ribbonTabInsert_drpDnBtnInsertBarcode') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertBarcode?.style.setProperty('display', 'none')

    let ribbonTabInsert_btnInsertSignature = document.getElementById('ribbonTabInsert_btnInsertSignature') as HTMLElement;
    ribbonTabInsert_btnInsertSignature?.style.setProperty('display', 'none')

    let ribbonGroupDataSource = document.getElementById('ribbonGroupDataSource') as HTMLElement;
    ribbonGroupDataSource?.style.setProperty('display', 'none')

    let ribbonGroupView = document.getElementById('ribbonGroupView') as HTMLElement;
    ribbonGroupView?.style.setProperty('display', 'none')

    let ribbonGroupMailMergeFinish = document.getElementById('ribbonGroupMailMergeFinish') as HTMLElement;
    ribbonGroupMailMergeFinish?.style.setProperty('display', 'none')

    let includeText = document.getElementById('txui-id-707') as HTMLElement;
    includeText?.style.setProperty('display', 'none')

    let next = document.getElementById('txui-id-709') as HTMLElement;
    next?.style.setProperty('display', 'none')

    let nextIf = document.getElementById('txui-id-710') as HTMLElement;
    nextIf?.style.setProperty('display', 'none')

    // let tabTableLayout = document.getElementById('tabTableLayout') as HTMLElement;
    // tabTableLayout?.style.setProperty('display', 'none')
  }

  private resizeDocumentEditor() {   
    if (this.documentEditorContainer) {
      const container = this.documentEditorContainer.nativeElement.parentElement;
      
      // Get the position and size of the container
      const containerRect = container.getBoundingClientRect();
      
      // Calculate available width and height
      const availableWidth = window.innerWidth - containerRect.left;
      const availableHeight = window.innerHeight - containerRect.top;
      
      // Set the size of the document editor
      this.documentEditorContainer.nativeElement.style.width = `${availableWidth - 20}px`;
      this.documentEditorContainer.nativeElement.style.height = `${availableHeight - 20}px`;
    }
  }
}
