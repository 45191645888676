import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { EventTypes, LogoutAuthOptions, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ErrorCodes } from './common/types/enums/error-codes.enum';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_ID } from '@angular/core';
import { SessionService } from './common/api-clients/session.service';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ModuleOvm } from './types/viewModels/ModuleOvm';
import { ModuleType } from './common/types/enums/moduleType.enum';
import { filter, firstValueFrom } from 'rxjs';
import { GetModulesCmdlet } from './modules/cmdlets/GetModulesCmdlet';
import { VoidArg } from './types/helpers/VoidArg';
import { MatDialog } from '@angular/material/dialog';
import { HelpComponent } from './common/components/help/help.component';
import { AppInsightsLoggerService } from './services/logging.service';
import { Item } from './common/types/models/menu-item.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  navBarCulture: string = 'nl-NL';
  subHeaderItems: any[] = [];
  modules: ModuleOvm[];
  activeModuleId: number | null = null;
  currentModuleType: ModuleType = ModuleType.Unknown;
  activeSubMenuItemId: string;
  shouldRedirectToFirstModule: boolean = false;
  helpItems: Item[] = [];
  isYouforce: boolean = true;
  url: string = '/assets/companies.json';
  companyData: any;
  private isRedirecting: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public oidcSecurityService: OidcSecurityService,
    private publicEventsService: PublicEventsService,
    private sessionService: SessionService,
    private getAModulesCmdlet: GetModulesCmdlet,
    private titleService: Title,
    private dialog: MatDialog,
    private logger: AppInsightsLoggerService,
    private renderer: Renderer2,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(DOCUMENT) private document: Document) {

    this.setIsYouforce();

    this.publicEventsService
      .registerForEvents()
      .subscribe((notification) => {
        if (notification.type === EventTypes.CheckingAuthFinishedWithError || notification.type === EventTypes.ConfigLoadingFailed) {
          console.error('Error during authentication process:', notification);
          this.logger.error('Error during authentication process:', notification);
          this.isLoading = false;
          this.router.navigate(['/error'], { queryParams: { warning: ErrorCodes.NoAccess } });
        } else if (notification.type === EventTypes.SilentRenewFailed) {
          console.warn('Silent renew failed:', notification);
          this.logger.warn('Silent renew failed:', notification);
          this.isLoading = false;
          this.router.navigate(['/sessionended']);
        } else {
          //console.log('Authentication event:', JSON.stringify(notification));
        }
      });
  }

  ngOnDestroy(): void {

  }

  async ngOnInit(): Promise<void> {
    // Check browser language
    const browserLang = navigator.language.toLowerCase();
    const supportedLang = browserLang.startsWith('en') ? 'en' : 'nl';

    this.translate.setDefaultLang(supportedLang);
    this.translate.use(supportedLang);

    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    if (lang) {
      let langParam = 'nl-NL';
      if (langParam.startsWith('en')) {
        langParam = 'en-GB';
      }
      this.sessionService.queryParamLanguage = langParam;
    }

    this.oidcSecurityService
      .checkAuth()
      .subscribe(async ({ isAuthenticated }) => {
        if (isAuthenticated) {
          await this.initializeModulesAndSession();
        }
      });
  }

  onSubHeaderItemClicked(item: any) {
    if (item) {
      this.navigateTo(item.id);
    }
  }

  onLogoutClicked(eventDetail: any) {
    this.oidcSecurityService.logoffLocal();
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {
      const resolve = eventDetail?.detail?.resolve;
      if (resolve) {
        resolve();
      }
    });
  }

  onNonYouforceLogoutClicked(eventDetail: any) {       
    this.oidcSecurityService.logoffLocal();
    this.oidcSecurityService.logoffAndRevokeTokens().subscribe(() => {      
      // redirect to environment.nonYouforceLogoutUrl
      window.location.href = environment.nonYouforceLogoutUrl;
    });
  }

  onHelpItemClicked(helpItem: any) {
    if (this.dialog.openDialogs.length > 0) {
      return; // Voorkom dat de dialoog nogmaals wordt geopend
    }

    this.dialog.open(HelpComponent, { data: { id: helpItem.id } });
  }

  private async initializeModulesAndSession() {
    try {
      this.isLoading = true;

      // Listen to session updates
      this.sessionService.sessionSet.subscribe((session) => {

        if (session) {
          const userCulture = session.culture || 'nl-NL';

          this.logger.setAuthenticatedUserId(session.userId);
          this.logger.logEvent('SessionInitialized', { userId: session.userId, culture: userCulture, isSuperUser: session.isSuperUser, companyId: session.companyId });
          this.navBarCulture = userCulture;
          this.setLocale(userCulture);

          // Stel de titel in met vertaling
          this.translate.get('common.title').subscribe((title: string) =>
            this.titleService.setTitle(title)
          );

          // Fetch the modules
          this.getAModulesCmdlet.execute(new VoidArg()).then((modules) => {
            this.modules = modules;

            // If there are no modules, go to no access page
            if (!modules || modules.length === 0) {
              this.router.navigate(['/error'], { queryParams: { warning: ErrorCodes.NoAccess } });
              return;
            }

            this.setupSubMenuItems(session.isSuperUser);
            this.setupHelpItems(session.isSuperUser);
            this.updateActiveModuleId();
            this.setActiveSubMenuItem();

            if (this.shouldRedirectToFirstModule &&
              (this.currentModuleType == ModuleType.RegularModule || this.currentModuleType == ModuleType.Unknown) &&
              this.activeModuleId === null &&
              this.modules.length > 0) {
              const firstModuleId = this.modules[0].id;
              this.router.navigate([`/modules/${firstModuleId}/details`]);
            }
          }).catch((error) => {
            console.error('Error fetching modules:', error);
            this.router.navigate(['/error'], { queryParams: { error: 'ErrorFetchingModules' } });
          });

        }
      });

      // Call the session endpoint to get the session
      const session = await firstValueFrom(this.sessionService.getSession());

      if (session && session.companyId) {
        if (await this.redirectBasedOnCompany(session.companyId))
          return;
      }

      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        this.updateActiveModuleId();
        this.setActiveSubMenuItem();
      });


    } catch (error) {
      console.error('Error in initializeModulesAndSession:', error);
      this.logger.error('Error in initializeModulesAndSession:', error);
      if (error.status === 404 || error.status === 401) {
        this.router.navigate(['/error'], { queryParams: { warning: ErrorCodes.NoAccess } });
      } else {
        this.router.navigate(['/error'], { queryParams: { warning: ErrorCodes.CannotInitialize } });
      }

    } finally {
      // Do not show the UI if redirecting to old TM
      if (!this.isRedirecting) {                
        this.isLoading = false;
      }
    }
  }

  private updateActiveModuleId() {
    const url = this.router.url;
    const moduleRegex = /\/modules\/(\d+)\//;
    const globalModuleRegex = /\/global-modules\/(\d+)\//;
    const baseTemplatesRegex = /\/base-templates\//;
    const baseModulesRegex = /^\/modules\/?$/; // Voor /modules of /modules/

    let match = url.match(moduleRegex);
    if (match && match[1]) {
      this.activeModuleId = +match[1];
      this.currentModuleType = ModuleType.RegularModule;
      this.shouldRedirectToFirstModule = false; // Specifieke module-route gevonden
      return;
    }

    match = url.match(globalModuleRegex);
    if (match && match[1]) {
      this.activeModuleId = +match[1];
      this.currentModuleType = ModuleType.GlobalModule;
      this.shouldRedirectToFirstModule = false; // Global module-route gevonden
      return;
    }

    match = url.match(baseTemplatesRegex);
    if (match) {
      this.activeModuleId = null;
      this.currentModuleType = ModuleType.BaseTemplates;
      this.shouldRedirectToFirstModule = false; // Base templates-route gevonden
      return;
    }

    // Controleer of het pad exact '/modules' of '/modules/' is zonder ID
    if (url.match(baseModulesRegex)) {
      this.activeModuleId = null;
      this.currentModuleType = ModuleType.Unknown;
      this.shouldRedirectToFirstModule = true; // Markeer om door te sturen naar de eerste module
      return;
    }

    // Geen match, route is onbekend en geen doorverwijzing naar een module vereist
    this.activeModuleId = null;
    this.currentModuleType = ModuleType.Unknown;
    this.shouldRedirectToFirstModule = false;
  }

  private setupSubMenuItems(isSuperUser: boolean) {

    // If there are no modules, do not add a sub header
    if (!this.modules || this.modules.length === 0) {
      this.subHeaderItems = [];
      return;
    }

    this.translate.get([
      'headers.templates',
      'headers.base_templates',
      'headers.global_templates'])
      .subscribe((translations: string) => {
        const menuItems = [
          {
            id: 'templates',
            displayName: translations['headers.templates'],
            active: this.currentModuleType === ModuleType.RegularModule,
            subItems: this.modules.map(module => ({
              id: `modules/${module.id}/details`,
              displayName: module.name,
              active: this.currentModuleType === ModuleType.RegularModule && this.activeModuleId === module.id
            }))
          },
          {
            id: 'base-templates/details',
            displayName: translations['headers.base_templates'],
            active: this.currentModuleType === ModuleType.BaseTemplates
          }
        ];

        if (isSuperUser) {
          menuItems.push({
            id: 'raet-templates',
            displayName: translations['headers.global_templates'],
            active: this.currentModuleType === ModuleType.GlobalModule,
            subItems: this.modules.map(module => ({
              id: `global-modules/${module.id}/details`,
              displayName: `${module.name} (Raet)`,
              active: this.currentModuleType === ModuleType.GlobalModule && this.activeModuleId === module.id
            }))
          });
        }

        this.subHeaderItems = menuItems;
      });
  }

  private setupHelpItems(isSuperUser: boolean) {
    this.helpItems = [
      { id: 'moduleDescText', displayName: this.translate.instant('helpItems.moduleDescText') },
      { id: 'mergeFunctionDescText', displayName: this.translate.instant('helpItems.mergeFunctionDescText') },
      { id: 'templateDescText', displayName: this.translate.instant('helpItems.templateDescText') },
      { id: 'templateDetailsDescText', displayName: this.translate.instant('helpItems.templateDetailsDescText') },
      { id: 'templateEditorDescText', displayName: this.translate.instant('helpItems.templateEditorDescText') },
      { id: 'baseTemplateDescText', displayName: this.translate.instant('helpItems.baseTemplateDescText') },
      { id: 'baseTemplateDetailsDescText', displayName: this.translate.instant('helpItems.baseTemplateDetailsDescText') },
      { id: 'baseTemplateEditorDescText', displayName: this.translate.instant('helpItems.baseTemplateEditorDescText') }
    ];
  }

  // Set activeSubMenuItemId based on the activeModuleId and currentModuleType
  private setActiveSubMenuItem() {
    if (this.currentModuleType === ModuleType.RegularModule) {
      this.activeSubMenuItemId = `modules/${this.activeModuleId}/details`;
    } else if (this.currentModuleType === ModuleType.GlobalModule) {
      this.activeSubMenuItemId = `global-modules/${this.activeModuleId}/details`;
    } else if (this.currentModuleType === ModuleType.BaseTemplates) {
      this.activeSubMenuItemId = 'base-templates/details';
    } else {
      this.activeSubMenuItemId = null;
    }
  }

  private navigateTo(menuId: string): void {
    if (!menuId) {
      return;
    }

    const routeSegments = menuId.split('/');
    this.router.navigate(routeSegments);
  }

  private setLocale(locale: string) {
    this.translate.setDefaultLang(locale.split('-')[0]);
    this.translate.use(locale.split('-')[0]);

    this.document.documentElement.lang = locale;
    this.renderer.setAttribute(this.document.documentElement, 'lang', locale);
    this.localeId = locale;
  }

  private setIsYouforce() {
    const currentHostname = window.location.hostname.toLowerCase();
    const youforceDomains = environment.youforceUrls.toLowerCase().split(',');

    this.isYouforce = youforceDomains.some(domain => currentHostname.includes(domain));
  }

  private initializeNavigationBar(userCulture: string) {
    this.setLocale(userCulture);
  }

  private async redirectBasedOnCompany(companyId: number): Promise<boolean> {    

    try {
      const res = await fetch(this.url);
      const json = await res.json();
      this.companyData = json;

      if (!this.companyData[0].companyIds.includes(Number(companyId))) {
          window.location.href = environment.oldTmUrl;
          this.isRedirecting = true;            
          return false; 
      }

    } catch (error) {
        console.error('Error fetching company data:', error);
    }

    return true; 
  }

}
