import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { EmptyTemplateIm } from '../types/EmptyTemplateIm';

@Injectable({ providedIn: 'root' })
export class CreateEmptyTemplateJob implements IJob<{ model: EmptyTemplateIm, globalMode: boolean }, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: { model: EmptyTemplateIm, globalMode: boolean }): Promise<any> {
    let result = await this.templatesApiClient.createEmptyTemplate2(arg.model, arg.globalMode);
    return result;
  }
}
