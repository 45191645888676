import { Injectable } from '@angular/core';
import { from, map, Observable, BehaviorSubject, tap, of, throwError } from 'rxjs';
import { UserSession } from '../types/models/user-session.model';
import { ApiEndpointClient } from './ApiEndpointClient';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private mySession: UserSession | null = null;
  private sessionSetSubject = new BehaviorSubject<UserSession | null>(null);
  private myQueryParamLanguage: string | null = null;

  constructor(private apiEndpointClient: ApiEndpointClient) { }

  getSession(): Observable<UserSession> {

    // Check if session is already available, return immediately if so
    if (this.mySession) {
      // Force use of latest myQueryParamLanguage if it's set
      this.applyQueryParamLanguage();
      return of(this.mySession);
    }

    // Fetch session from API if not already fetched
    let url: string = '/session';
    console.log()
    let dataPromise = this.apiEndpointClient.get<any>(url);
    console.log(dataPromise)
    return from(dataPromise).pipe(
      map(response => ({
        // Use myQueryParamLanguage if set, otherwise use response.language
        culture: this.myQueryParamLanguage || response.language,
        isSuperUser: response.isSuperUser,
        userId: response.userId,
        companyId: response.companyId
      } as UserSession)),
      tap(session => {
        // Save session and push to sessionSetSubject
        this.mySession = session;
        this.sessionSetSubject.next(session);
      })
    );
  }

  private applyQueryParamLanguage() {
    // Update the culture if myQueryParamLanguage is set and mySession exists
    if (this.mySession && this.myQueryParamLanguage) {
      this.mySession.culture = this.myQueryParamLanguage;
      this.sessionSetSubject.next(this.mySession); // Push updated session to subscribers
    }
  }

  get session(): UserSession | null {
    return this.mySession;
  }

  get sessionSet(): Observable<UserSession | null> {
    // Push current mySession if already available to ensure subscribers get latest
    if (this.mySession) {
      this.sessionSetSubject.next(this.mySession);
    }
    return this.sessionSetSubject.asObservable();
  }

  set queryParamLanguage(value: string) {
    this.myQueryParamLanguage = value;
    this.applyQueryParamLanguage(); // Apply the new query param to the session if it exists
  }
}
