import { Injectable } from '@angular/core';
import { ApiEndpointClient } from 'src/app/common/api-clients/ApiEndpointClient';
import { AuthOwnHttpClient } from 'src/app/common/api-clients/AuthOwnHttpClient';
import { TemplatesOdm } from 'src/app/types/dataModels/TemplateOdm';
import { BlobHttpContent, JsonHttpContent } from 'src/app/types/helpers/HttpContent';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { TemplateVersionUpdate4Meta } from 'src/app/types/dataModels/TemplateVersionUpdate4Meta';
import { RaetFile } from 'src/app/types/dataModels/RaetFile';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { DataDefinitionDm } from '../types/DataDefinitionDm';
import { TemplateCopyIm } from '../types/TemplateCopyIm';
import { TemplateIm } from '../types/TemplateIm';
import { TemplateUpdate4Meta } from '../types/TemplateUpdate4Meta';
import { TemplateVersionIm } from '../types/TemplateVersionIm';
import { TemplateVersionReq } from '../types/TemplateVersionReq';
import { TemplateVersionRevertIm } from '../types/TemplateVersionRevertIm';
import { Router } from '@angular/router';
import { TemplateVersionFromTemplateIm } from '../types/TemplateVersionFromTemplateIm';
import { TemplateDelm } from '../types/TemplateDelm';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { EmptyTemplateIm } from '../types/EmptyTemplateIm';

@Injectable({ providedIn: "root" })
export class TemplatesApiClient {

  constructor(private apiEndpointClient: ApiEndpointClient, private httpClient: AuthOwnHttpClient, private router: Router,) { }

  private cache = new Map<number, TemplateDdm>();

  clearTemplateCache() {
    this.cache.clear();
  }

  async getTemplateFileMetaByIdJob(id: number, forceRefresh: boolean = false): Promise<TemplateDdm> {

    if (!forceRefresh && this.cache.has(id)) {
      return this.cache.get(id)!;
    }

    const url: string = '/template/' + id + '/meta';
    const data = await this.apiEndpointClient.get<TemplateDdm>(url);

    // Clear the cache
    this.cache.clear();
    this.cache.set(id, data);
    return data;
  }

  async createTemplate(templateIm: TemplateIm, templateExtension: string, globalMode: boolean): Promise<any> {

    // if (!templateIm?.file.content) {
    //   return this.createEmptyTemplate(templateIm);
    // }

    let url: string = globalMode ? '/globaltemplate' : '/template';

    let blobContent = new BlobHttpContent();
    blobContent.fileName = templateIm.name + '.' + templateExtension;
    blobContent.content = templateIm.file.content;

    let jsonContent = new JsonHttpContent();
    templateIm.file.content = templateIm.file.content;
    jsonContent.content = JSON.stringify(templateIm);

    let result = await this.httpClient.postMultipart(url, jsonContent, blobContent);
    return result;
  }

  async createEmptyTemplate(templateIm: TemplateIm): Promise<any> {
    const url = '/template';

    const jsonContent = {
      description: templateIm.description,
      mergeFunctionId: templateIm.mergeFunctionId,
      name: templateIm.name
    };

    const result = await this.httpClient.send('POST', url, jsonContent);

    return result;
  }

  async getTemplates(clientId?: number): Promise<TemplatesOdm[]> {
    let url: string = '/template';
    let data = await this.apiEndpointClient.get<TemplatesOdm[]>(url);
    return data;
  }

  async updateTemplateVersionMeta(templateVersionUpdate4Meta: TemplateVersionUpdate4Meta): Promise<VoidResult> {
    let url: string = '/template-version/' + templateVersionUpdate4Meta.id + '/meta';
    await this.apiEndpointClient.put(url, templateVersionUpdate4Meta);
    return new VoidResult();
  }

  async getTemplateBlob(id: number): Promise<Blob> {
    let url = `/template/${id}/blob`;
    return await this.apiEndpointClient.get(url);
  }

  async getTemplateBlob2(id: number): Promise<RaetFile> {
    const self = this;
    let url = `/template/${id}/blob`;
    let httpContents = await this.apiEndpointClient.getMultipart2(url);

    let result = new TemplateEditorFileModel();

    return httpContents;
  }

  async getTemplateBlob3(templateVersionReq: TemplateVersionReq): Promise<Blob> {
    let url = `/template/${templateVersionReq.templateId}/documentType/${templateVersionReq.documentType}/blob`;
    let httpContents = await this.apiEndpointClient.getFile(url);
    return httpContents;
  }

  private addBlob(result: TemplateEditorFileModel, blobHttpContent: BlobHttpContent) {
    result.name = blobHttpContent.fileName;
    result.content = blobHttpContent.content;
  }

  async getMergeDataSpecification(mergeFunctionId: number): Promise<DataDefinitionDm> {
    let url: string = '/mergefunction/' + mergeFunctionId + '/merge-data-specification';
    let data = await this.apiEndpointClient.get<any>(url);
    return data;
  }

  async updateSourceFileContent(template: TemplateDdm): Promise<void> {
    let url = `/template/${template.id}/content`;
    let blobContent = new BlobHttpContent();
    blobContent.fileName = template.name;
    blobContent.content = template.file.content;
    let jsonContent = new JsonHttpContent();
    jsonContent.content = JSON.stringify({ id: template.id });
    await this.httpClient.putMultipart(url, jsonContent, blobContent);
  }

  async createTemplateVersion(templateVersionIm: TemplateVersionIm): Promise<void> {
    let url = `/template-version/${templateVersionIm.templateId}`;
    let result = await this.httpClient.send('POST', url, templateVersionIm);
  }

  async createTemplateVersion222(templateVersionIm: TemplateVersionIm): Promise<void> {
    let url = `/template-version/${templateVersionIm.templateId}`;
    let blobContent = new BlobHttpContent();
    blobContent.fileName = templateVersionIm.name;
    blobContent.content = templateVersionIm.file.content;
    let jsonContent = new JsonHttpContent();
    jsonContent.content = JSON.stringify({ templateId: templateVersionIm.templateId, description: templateVersionIm.description, name: templateVersionIm.name });
    let result = await this.httpClient.postMultipart(url, jsonContent, blobContent);
  }

  async createEmptyTemplateVersion(templateId: number): Promise<void> {
    let url = `/template-version/` + templateId + `/create-empty`;
    let result = await this.httpClient.send('POST', url);
  }

  async createEmptyTemplate2(emptyTemplateIm: EmptyTemplateIm, globalMode: boolean): Promise<any> {
    let url: string = globalMode ? '/globaltemplate/empty' : '/template/empty';
    let result = await this.httpClient.send('POST', url, emptyTemplateIm);
    return result;
  }

  async copyTemplate(templateCopyIm: TemplateCopyIm, globalMode: boolean): Promise<any> {
    let url: string = globalMode ? '/globaltemplate/copy' : '/template/copy';
    let result = await this.httpClient.send('POST', url, templateCopyIm);
    return result
  }

  async createTemplateVersionFromId(templateVersionFromTemplateIm: TemplateVersionFromTemplateIm): Promise<any> {
    let url: string = '/template-version/' + templateVersionFromTemplateIm.templateId + '/from-template';
    let result = await this.httpClient.send('POST', url, templateVersionFromTemplateIm);
    return result
  }

  async updateTemplateFileMeta(templateUpdate4Meta: TemplateUpdate4Meta, globalMode: boolean): Promise<void> {
    const baseUrl = globalMode ? '/globaltemplate/' : '/template/';
    let url: string = baseUrl + templateUpdate4Meta.id + '/meta';
    await this.httpClient.send('PUT', url, templateUpdate4Meta);
  }

  async revertTemplateVersion(templateVersionRevertIm: TemplateVersionRevertIm): Promise<void> {
    let url: string = '/template-version/' + templateVersionRevertIm.templateId + '/revert';
    await this.httpClient.send('POST', url, templateVersionRevertIm);
  }

  async deleteTemplate(templateDelm: TemplateDelm, globalMode: boolean): Promise<void> {
    const baseUrl = globalMode ? '/globaltemplate/' : '/template/';
    let url: string = baseUrl + templateDelm.templateId;
    await this.httpClient.send('PUT', url, templateDelm);
  }
}
