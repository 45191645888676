import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { ComponentService } from 'src/app/services/ComponentService';
import { DataDefinitionDm } from '../../types/DataDefinitionDm';
import { ProcessingStorage } from 'src/app/common/services/ProcessingStorage';
import { GetMergeDataSpecificationCmdlet } from '../../cmdlets/GetMergeDataSpecificationCmdlet';
declare const TXTextControl: any;

@Component({
  selector: 'app-if-field-dialog',
  templateUrl: './if-field-dialog.component.html',
  styleUrl: './if-field-dialog.component.scss'
})

export class IfFieldDialogComponent extends DialogComponentBase<IfFieldDialogComponent, boolean> implements OnInit {

  expressionField: any;
  selectedOperator: any = '0';
  valueToCompare: any;
  trueText: any = null;
  falseText: any = null;
  mergeDataSpecification: DataDefinitionDm;
  categories: string[] = [];
  subCategories: string[] = [];
  mergeFieldNames: string[] = [];
  selectedCategory: string;
  selectedSubCategory: string;
  selectedMergeField: string;
  mergeFieldError: boolean = false;
  mergeFunctionId: any;

  constructor(componentService: ComponentService,
    private getMergeDataSpecificationCmdlet: GetMergeDataSpecificationCmdlet,
    private localStorage: ProcessingStorage,
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogRef: MatDialogRef<IfFieldDialogComponent, boolean>) {
    super(componentService, dialogRef);
    this.mergeFunctionId = data.mergeFunctionId;
  }

  async ngOnInit() {
    super.ngOnInit();
    try {
      this.mergeDataSpecification = await this.getMergeDataSpecificationCmdlet.execute(this.mergeFunctionId);
      console.log(this.mergeDataSpecification)

      for (let mergeField of this.mergeDataSpecification.mergeFields) {

        if (this.categories.indexOf(mergeField.category) === -1) {
          this.categories.push(mergeField.category);
        }

        this.selectedCategory = this.categories[0];

        if (this.subCategories.indexOf(mergeField.subCategory) === -1 && this.selectedCategory === mergeField.category) {
          this.subCategories.push(mergeField.subCategory);
        }

        this.selectedSubCategory = this.subCategories[0];

        if (mergeField.category === this.selectedCategory) {
          this.mergeFieldNames.push(mergeField.name);
        }

        // if (mergeField.category === null && mergeField.subCategory === null) {
        //   this.mergeFieldNames.push(mergeField.name);
        // }
        this.filterMergeFields(this.selectedCategory, this.selectedSubCategory)
      }
    } catch (e) {
      this.fillEmptyCategories()
    }
    this.fillEmptyCategories()
    this.isLoaded = true;
  }

  okCallback() {
    this.expressionField = new TXTextControl.IfField();
    this.expressionField.expression1 = this.selectedMergeField;
    this.expressionField.operator = +this.selectedOperator;
    this.expressionField.expression2 = this.valueToCompare;
    this.expressionField.trueText = this.trueText;
    this.expressionField.falseText = this.falseText;

    if (this.expressionField.expression1 === undefined || this.expressionField.expression1 === null) {
      this.mergeFieldError = true;
      this.cancelCallback()
    }
    else {
      TXTextControl.addMergeField(this.expressionField);
      console.log('okCallback from if dialog')
      console.log(this.expressionField)
      this.result = true;
      this.close();
    }
  }

  onMouseDown($event) {

  }

  cancelCallback() {

  }

  filterMergeFields(selectedCategory: string, selectedSubCategory: string) {
    this.mergeFieldNames.length = 0;

    for (let mergeField of this.mergeDataSpecification.mergeFields) {

      this.selectedCategory = selectedCategory;

      if (this.subCategories.indexOf(mergeField.subCategory) === -1 && mergeField.subCategory !== null && mergeField.category === selectedCategory) {
        this.subCategories.push(mergeField.subCategory);
      }

      if (mergeField.category === this.selectedCategory && mergeField.subCategory === selectedSubCategory) {
        this.mergeFieldNames.push(mergeField.name);
      }
    }
  }

  onCategoryChange($event) {
    console.log($event.target.value)
    this.selectedCategory = $event.target.value;
    this.getSubCategories(this.selectedCategory);
    this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
  }

  onSubCategoryChange($event) {
    console.log($event.target.value)
    this.selectedSubCategory = $event.target.value;
    this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
  }

  updateSelectedMergeField() {

  }

  getSubCategories(selectedCategory: string) {
    this.selectedCategory = selectedCategory;
    this.subCategories.length = 0;

    for (let mergeField of this.mergeDataSpecification.mergeFields) {
      if (mergeField.category === selectedCategory && mergeField.subCategory === null) {
        this.subCategories.push(null);
        break;
      }
    }

    for (let mergeField of this.mergeDataSpecification.mergeFields) {
      if (this.subCategories.indexOf(mergeField.subCategory) === -1 && mergeField.category === selectedCategory && mergeField.subCategory !== null) {
        this.subCategories.push(mergeField.subCategory);
      }
    }

    if (this.subCategories.length !== 0) {
      this.filterMergeFields(this.selectedCategory, this.selectedSubCategory);
    }
    this.selectedSubCategory = this.subCategories[0]
  }

  fillEmptyCategories() {
    if (this.categories.length === 0) {
      this.categories.push('-');
      this.subCategories.push('-');
    }

    if (this.subCategories.length === 0) {
      this.subCategories.push('-');
    }

    if (this.mergeFieldNames.length === 0) {
      this.mergeFieldNames.push('-');
    }
  }
}
