import { LOCALE_ID, NgModule } from '@angular/core';
import { DocumentEditorModule } from '@txtextcontrol/tx-ng-document-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TemplateEditorComponent } from './templates/components/template-editor/template-editor.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ToolbarComponent } from './common/components/toolbar/toolbar.component';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { BaseTemplatesComponent } from './base-templates/components/base-template-overview/base-templates.component';
import { RouterOutlet } from '@angular/router';
import { SearchFieldComponent } from './common/components/search-field/search-field.component';
import { MergeFunctionDropdownComponent } from './common/components/merge-function-dropdown/merge-function-dropdown.component';
import { PaginatorComponent } from './common/components/paginator/paginator.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MergeFunctionOverviewComponent } from './merge-functions/components/merge-function-overview/merge-function-overview.component';
import { UploadBaseTemplateComponent } from './common/components/upload-template/upload-template.component';
import { UserMessagesComponent } from './common/components/user-messages/user-messages.component';
import { ToolbarPrimaryButtonComponent } from './common/components/toolbar-primary-button/toolbar-primary-button.component';
import { TableRowPrimaryMenuButtonComponent } from './common/components/table-row-primary-menu-button/table-row-primary-menu-button.component';
import { BaseTemplateUpdateStartComponent } from './base-templates/components/base-template-update-start/base-template-update-start.component';
import { BaseTemplateDeleteComponent } from './base-templates/components/base-template-delete/base-template-delete.component';
import { MergeFunctionDetailsComponent } from './merge-functions/components/merge-function-details/merge-function-details.component';
import { TableRowPrimaryButtonComponent } from './common/components/table-row-primary-button/table-row-primary-button.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { TemplateAddStartComponent } from './common/components/template-add-start/template-add-start.component';
import { TemplateDetailsComponent } from './templates/components/template-details/template-details.component';
import { BaseTemplateDetailsComponent } from './base-templates/components/base-template-details/base-template-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CustomFieldDialogComponent } from './templates/components/custom-field-dialog/custom-field-dialog.component';
import { IfFieldDialogComponent } from './templates/components/if-field-dialog/if-field-dialog.component';
import { EditIfFieldDialogComponent } from './templates/components/edit-if-field-dialog/edit-if-field-dialog.component';
import { DropdownButtonComponent } from './common/components/dropdown-button/dropdown-button.component';
import { TemplateRedoComponent } from './common/components/template-redo/template-redo.component';
import { BaseTemplateEditorComponent } from './base-templates/components/base-template-editor/base-template-editor.component';
import { TemplateVersionsComponent } from './templates/components/template-versions/template-versions.component';
import { RaetTemplateOverviewComponent } from './raet-templates/components/raet-template-overview/raet-template-overview.component';
import { RaetTemplateDetailsComponent } from './raet-templates/components/raet-template-details/raet-template-details.component';
import { AlertComponent } from "./common/components/alert/alert.component";
import { RaetTemplateEditorComponent } from './raet-templates/components/raet-template-editor/raet-template-editor.component';
import { EmptyTemplateEditorComponent } from './common/components/empty-template-editor/empty-template-editor.component';
import { EmptyRaetTemplateEditorComponent } from './raet-templates/components/empty-raet-template-editor/empty-raet-template-editor.component';
import { UploadReplaceTemplateComponent } from './common/components/upload-replace-template/upload-replace-template.component';
import { UploadRaetTemplateComponent } from './raet-templates/components/upload-raet-template/upload-raet-template.component';
import { StaticMergeFunctionOverviewComponent } from './raet-templates/components/static-merge-function-overview/static-merge-function-overview.component';
import { AuthInterceptor, AuthModule, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';
import { OpenIdConfigService } from './common/auth/OpenIdConfigService';
import { LoggedOutComponent } from './common/auth/logged-out.component';
import { SigninPingComponent } from './common/auth/signin-ping.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { ShellComponent } from './shell/components/shell/shell.component';
import { ModulePanelComponent } from './modules/components/module-panel/module-panel.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RefreshButtonComponent } from './common/components/refresh-button/refresh-button.component';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import { ConfirmationDialogComponent } from './common/components/confirmation-dialog/confirmation-dialog.component';
import { YouforceNavigationBarComponent } from './common/components/youforce-navigation-bar/youforce-navigation-bar.component';
import { BreadCrumbsComponent } from './common/components/bread-crumbs/bread-crumbs.component';
import { HelpComponent } from './common/components/help/help.component';
import { AppInsightsLoggerService } from './services/logging.service';
import { MergeBlockDialogComponent } from './templates/components/merge-block-dialog/merge-block-dialog.component';
import { DateFieldDialogComponent } from './templates/components/date-field-dialog/date-field-dialog.component';
import { NavBarComponent } from './common/components/nav-bar/nav-bar.component';
import { DropDownIconComponent } from './common/components/drop-down-icon/drop-down-icon.component';
import { MenuDropdownComponent } from './common/components/menu-dropdown/menu-dropdown.component';

const authFactory = (configService: OpenIdConfigService) => {
  const config = configService.getConfig();
  return new StsConfigStaticLoader(config);
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Registreer de locale data
registerLocaleData(localeNl, 'nl-NL');
registerLocaleData(localeEn, 'en-US');

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    TemplateEditorComponent,
    PageNotFoundComponent,
    ToolbarComponent,
    BaseTemplatesComponent,
    SearchFieldComponent,
    MergeFunctionDropdownComponent,
    PaginatorComponent,
    MergeFunctionOverviewComponent,
    UploadBaseTemplateComponent,
    UserMessagesComponent,
    ToolbarPrimaryButtonComponent,
    TableRowPrimaryMenuButtonComponent,
    BaseTemplateUpdateStartComponent,
    BaseTemplateDeleteComponent,
    MergeFunctionDetailsComponent,
    TableRowPrimaryButtonComponent,
    TemplateVersionsComponent,
    TemplateAddStartComponent,
    TemplateDetailsComponent,
    BaseTemplateDetailsComponent,
    CustomFieldDialogComponent,
    IfFieldDialogComponent,
    DropdownButtonComponent,
    TemplateRedoComponent,
    BaseTemplateEditorComponent,
    RaetTemplateOverviewComponent,
    RaetTemplateDetailsComponent,
    RaetTemplateEditorComponent,
    EmptyTemplateEditorComponent,
    EmptyRaetTemplateEditorComponent,
    UploadReplaceTemplateComponent,
    UploadRaetTemplateComponent,
    StaticMergeFunctionOverviewComponent,
    AlertComponent,
    SigninPingComponent,
    LoggedOutComponent,
    ErrorPageComponent,
    ModulePanelComponent,
    RefreshButtonComponent,
    ConfirmationDialogComponent,
    YouforceNavigationBarComponent,
    BreadCrumbsComponent,
    HelpComponent,
    MergeBlockDialogComponent,
    DateFieldDialogComponent,
    NavBarComponent,
    DropDownIconComponent,
    MenuDropdownComponent,
    EditIfFieldDialogComponent
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.baseAddress },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AppInsightsLoggerService
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    DocumentEditorModule,
    HttpClientModule,
    FormsModule,
    RouterOutlet,
    MatTableModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatSortModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    NgxSkeletonLoaderModule.forRoot(),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [OpenIdConfigService],
      }
    })
  ],
})
export class AppModule { }
